import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@lis-services';
import { LisDialogLocationDefaultOutput } from '@lis-types';
import { first } from 'lodash-es';
import { LisFormFieldSelect } from '../../form/types/form.type';

@Component({
  templateUrl: './dialog-location-default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogLocationDefaultComponent implements OnInit {
  public locationControl = new FormControl('', []);
  public locationField?: LisFormFieldSelect;

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<
      DialogLocationDefaultComponent,
      LisDialogLocationDefaultOutput
    >,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.init();
  }

  public onCtaClick(): void {
    this.dialogRef.close({
      locationId: this.locationControl.value,
    });
  }

  private async init(): Promise<void> {
    this.userService.locations$.subscribe((locations) => {
      const firstLocation = first(locations);
      if (firstLocation) {
        this.locationControl.setValue(firstLocation.locationId);
      }
      if (locations) {
        this.locationField = {
          label: 'dialog.location-default.form-field-label',
          type: 'select',
          options: locations.map((location) => ({
            key: location.locationId,
            label: location.city + ', ' + location.country,
          })),
        };
        this.cdRef.detectChanges();
      }
    });
  }
}
