<lis-dialog>
  <ng-container id="header">{{ 'dialog.location-default.headline' | translate }}</ng-container>

  <div>
    <lis-form-field *ngIf="locationField" [control]="locationControl" [field]="locationField"></lis-form-field>

    <lis-notification [type]="'info'" class="mt-4">
      {{ 'dialog.location-default.info' | translate }}
    </lis-notification>
  </div>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button lis-button type="button" [elastic]="true" (click)="onCtaClick()">{{ 'dialog.location-default.cta' | translate }}</button>
    </div>
  </ng-container>
</lis-dialog>
